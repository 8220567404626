import React, { Component } from 'react';
import './TabBar.css';

export default class TabBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bars:[
        {title:'推荐',icon:'imgs/car.png',selIcon:'imgs/car_sel.png'},
        {title:'烟杆',icon:'imgs/car.png',selIcon:'imgs/car_sel.png'},
        {title:'烟弹',icon:'imgs/car.png',selIcon:'imgs/car_sel.png'},
        {title:'购物车',icon:'imgs/car.png',selIcon:'imgs/car_sel.png'}
      ],
      selectIndex:0
    }
  }

  componentDidMount(){
      
  }

  onClick = (index)=>{
    this.setState({selectIndex:index});
    this.props.onTabChange(index);
  }
    
  render() {
    return (
        <div className="tabbar">
              <div className="tabbarContent">
              {this.state.bars.map((value,index)=>{
                return <div className="item" key={index} onClick={()=>{this.onClick(index)}}>
                          <img src={index === this.state.selectIndex ? value.selIcon : value.icon} alt=""/>
                          <p style={{color:index===this.state.selectIndex?'black':'gray'}}>{value.title}</p>
                        </div>
                
              })}
            </div>
            <div className="safeaire"/>
        </div>
    );
  }
}
